import request from '@/api/index.js'

export function reviewUsersID (params) {
  return request({
    url: '/api/user/reals/',
    method: 'get',
    params
  })
}

export function userIDInfo (name) {
  return request({
    url: `/api/user/reals/${name}`,
    method: 'get'
  })
}

export function reviewUserID (name, data) {
  return request({
    url: `/api/user/reals/${name}/review`,
    method: 'post',
    data
  })
}

export function getTeamsInfo (params = {}) {
  return request({
    url: '/api/teams',
    method: 'get',
    params
  })
}

export function getCurTeamInfo (id) {
  return request({
    url: `/api/teams/${id}`,
    method: 'get'
  })
}

export function getTeamHistory (id) {
  return request({
    url: `/api/teams/${id}/projects`,
    method: 'get'
  })
}

export function setComment (id, data = {}) {
  return request({
    url: `/api/teams/${id}`,
    method: 'post',
    data
  })
}
// 拿提现列表
export function getSalaryList () {
  return request({
    url: '/api/admin/wallet/withdraw/list?state=1&page=1&pageSize=100',
    method: 'get'
  })
}

// 打钱接口
export function hitMoney (data) {
  return request({
    url: '/api/admin/wallet/withdraw-logs/accept',
    method: 'post',
    data
  })
}

// 拒绝打钱接口
export function reviewSalary (data) {
  return request({
    url: '/api/admin/wallet/withdraw-logs/reject',
    method: 'post',
    data
  })
}

// 获取提现记录列表
export function withdrawList (name, page) {
  return request({
    url: `/api/admin/wallet/withdraw/list?username=${name}&state=0&page=${page}&pageSize=15`,
    method: 'get'
  })
}

// 获取收入记录列表
export function incomeList (name, page) {
  return request({
    url: `/api/admin/wallet/income/list?username=${name}&page=${page}&pageSize=15`,
    method: 'get'
  })
}
