<template>
<div class="box pb-5">
  <!-- 用户账单明细 -->
  <el-form :inline="true" :model="myForm" class="demo-form-inline">
    <el-form-item label="用户名">
      <el-input v-model="myForm.name" size="mini"></el-input>
    </el-form-item>
    <el-form-item label="查询内容">
      <el-select v-model="myForm.content" size="mini">
        <el-option label="收入明细" value="收入明细"></el-option>
        <el-option label="提现记录" value="提现记录"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item class="last" >
      <el-button type="primary" @click="search" size="mini">查询</el-button>
    </el-form-item>
  </el-form>
  <!-- 第一个 -->
   <el-table :data="firstArr"  class="first-table" v-if="myForm.content === '收入明细' && firstArr.length > 0 "
    border :header-cell-style="{ textAlign: 'center' }" :cell-style="{ textAlign: 'center' }">
      <el-table-column  label="时间"  >
         <template slot-scope="scope">
           <span>{{format(scope.row.createdAt)}}</span>
        </template>
         </el-table-column>
      <el-table-column  prop="username" label="用户名"> </el-table-column>
      <el-table-column  prop="amount" label="泛函币"> </el-table-column>
      <el-table-column  prop="comment" label="来源" width="280"> </el-table-column>
    </el-table>
<!-- 第二个 -->
 <el-table :data="secondArr"  v-if="myForm.content === '提现记录'&& secondArr.length > 0 "
    border :header-cell-style="{ textAlign: 'center' }" :cell-style="{ textAlign: 'center' }">
      <el-table-column  label="时间"  width="180">
         <template slot-scope="scope">
           <span>{{format(scope.row.completedAt)}}</span>
        </template>
         </el-table-column>
      <el-table-column  prop="username" label="用户名"> </el-table-column>
      <el-table-column  prop="amount" label="提现泛函币"> </el-table-column>
      <el-table-column prop="tax" label="税"> </el-table-column>
       <el-table-column   label="税后泛函币">
         <template slot-scope="scope">
           <span>{{scope.row.amount - scope.row.tax}}</span>
        </template>
        </el-table-column>
      <el-table-column label="提现结果">
         <template slot-scope="scope">
           <span>{{res(scope.row.state)}}</span>
        </template>
      </el-table-column>
    </el-table>
 <el-pagination v-if="myForm.content === '收入明细' && firstArr.length > 0 "  @current-change="firstHandleCurrentChange" :page-size="firstPager.pageSize" layout=" prev, pager, next, jumper" :total="firstPager.totalRow">
  </el-pagination>
  <el-pagination v-if="myForm.content === '提现记录' && secondArr.length > 0 "  @current-change="secondHandleCurrentChange" :page-size="secondPager.pageSize" layout=" prev, pager, next, jumper" :total="secondPager.totalRow">
  </el-pagination>
</div>
</template>

<script>
import { incomeList, withdrawList } from '@/api/reviews'
export default {
  name: 'bill',
  data () {
    return {
      myForm: {
        name: '',
        content: ''
      },
      firstArr: [],
      secondArr: [],
      firstPager: {
        page: 1,
        pageSize: 15,
        totalRow: ''
      },
      secondPager: {
        page: 1,
        pageSize: 15,
        totalRow: ''
      }
    }
  },
  methods: {
    async search () {
      if (this.myForm.content === '收入明细') {
        // const data = {
        //   username: this.myForm.name
        // }
        const res = await incomeList(this.myForm.name, this.firstPager.page)
        // console.log(res.data)
        if (res.data.items.length !== 0) {
          this.firstArr = res.data.items
          this.secondArr = []
          this.firstPager = res.data.pager
        }
      } else if (this.myForm.content === '提现记录') {
        const res = await withdrawList(this.myForm.name, this.secondPager.page)
        // console.log(res.data)
        if (res.data.items.length !== 0) {
          this.secondArr = res.data.items
          this.firstArr = []
          this.secondPager = res.data.pager
        }
      } else {
        this.$message({
          type: 'error',
          message: '请选择查询内容'
        })
      }
    },
    format (myTime) {
      const date = new Date(myTime * 1000)
      const y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? ('0' + MM) : MM
      let d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      let h = date.getHours()
      h = h < 10 ? ('0' + h) : h
      let m = date.getMinutes()
      m = m < 10 ? ('0' + m) : m
      let s = date.getSeconds()
      s = s < 10 ? ('0' + s) : s
      return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
    },
    firstHandleCurrentChange (p) {
      this.firstPager.page = p
      this.search()
    },
    secondHandleCurrentChange (p) {
      this.secondPager.page = p
      this.search()
    },
    res (state) {
      if (state === 99) {
        return '成功'
      } else if (state === 97) {
        return '失败'
      } else if (state === 1) {
        return '待审核'
      } else {
        return '状态未知'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.box{
  padding: 50px;
  .el-form{
    display: flex;
    .el-form-item{
      flex: 1;
    }
    .last1{
      display: flex;
      justify-content: center;
    }
  }
}
.el-table th.gutter {
    display: table-cell!important;
}
.pr-5{
  padding-right: 0 !important;
}
.pl-5{
  padding-left: 0 !important;
}
</style>
